import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import { useEffect, useState } from "react";
import { BarLoader } from "react-spinners";
import { ToastContainer } from 'react-toastify';
import { getAuthToken } from '../helpers/axios_helper';

//permet d'ajouter des utilisateurs en mêmem temps

const AddUser: React.FC = (props) => {
    const [nom, setNom] = useState("");
    const [login, setLogin] = useState("");
    const [role, setRole] = useState("");
    const [mdp, setmdp] = useState("");
    const [nomClient, setnomClient] = useState("");
    // const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const token = getAuthToken();
    let options = ["ADMIN_VANESSA", "FREYCON", "EVE", "DJAMAL", "EVE5555", "2RMUSIC", "JULIE", "AFONE INFRASTRUCTURE", "BASTIEN FOUCAT", "EUROMULTIMEDIA FORUM", "ISABELLE FOUCAT", "LE TELEPHONE ROSE DE JULIE", "RINEVA", "VIRGINIE TASSERY", "HV SARL", "MAYBE 57", "ADCI", "LISA FOUCAT", "GLOBEWEB"];
    let roles = ['ADMIN', 'USER'];


    // Charger les options depuis votre API en utilisant Axios



    const handleDropdownChangeOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setnomClient(selectedValue);
    };


    const handleDropdownChangeRole = (event: React.ChangeEvent<HTMLSelectElement>) => {
        
        const selected = event.target.value;
        if (selected =='ADMIN'){
            setRole(selected);
            setnomClient("ADMIN_VANESSA")
        }else{
            setRole(selected);
        }
        
    };

    const handleValidation = async () => {
        try {
            // Effectue la recherche en utilisant l'endpoint approprié avec Axios
            const token = getAuthToken(); // Récupérer le token du local storage
            const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
            const data = {
                "nom": nom,
                "login": login,
                "role": role,
                "password": mdp,
                "nomClient": nomClient
            }


            // Préparer les headers avec le token (s'il existe)
            try {
                await axios.post('https://afone-global-config.dt-srv-194.ucorp.io/api/v1/auth/register', data).then(rep=>window.location.reload())
            } catch (error) {
                console.error("Erreur d'ajout d'utilisateur :", error);

            }

            // Appelle la fonction onValidation avec la réponse de la recherche pour passer à l'étape suivante (étape 2)
        } catch (error) {
            // Gère les erreurs de la recherche ici
        }

    };

    const handleChangeNom = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNom(event.target.value);

    };
    const handleChangeLogin = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLogin(event.target.value)
    };
    const handleChangeMdp = (event: React.ChangeEvent<HTMLInputElement>) => {
        setmdp(event.target.value)
    };

    return (
        <>

            <div className="modal fade" id={"editerUser-" + nom} aria-hidden="true" aria-labelledby="exampleModalToggleLabel2">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <form action="">
                                <div className="float-end">
                                    <button type="button" id="btn-close-update" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div><br />
                                <center>
                                    <h2><b>Ajouter un utilisateur</b></h2>
                                    <div className="row mb-3">
                                        <div className="col-sm-12">
                                            <input type="text"
                                                className="form-control"
                                                id="inputNom" placeholder="Nom"
                                                value={nom}
                                                onChange={handleChangeNom}
                                                required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-sm-12">
                                            <input type="text"
                                                className="form-control"
                                                id="inputLogin" placeholder="login"
                                                value={login}
                                                onChange={handleChangeLogin} 
                                                required/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-sm-12">
                                            <input type="password"
                                                className="form-control"
                                                id="inputMdp"
                                                placeholder="Password"
                                                value={mdp}
                                                onChange={handleChangeMdp} 
                                                required/>
                                        </div>
                                    </div>
                                    
                                    <div className="row mb-3">
                                        <div>
                                        <select
                                                className="col-sm-12"
                                                onChange={handleDropdownChangeOption}
                                                style={{ height: "40px", borderRadius: '6px', fontFamily: 'initial' }}
                                                required
                                            >
                                                <option value="">Sélectionner un client</option>
                                                <option value="EUROMULTIMEDIA">EUROMULTIMEDIA</option>
                                                <option value="FOUCAT">FOUCAT</option>
                                                <option value="HV SARL">HV SARL</option>
                                                <option value="LE TELEPHONE ROSE DE JULIE">LE TELEPHONE ROSE DE JULIE</option>
                                                <option value="MONSIEUR EME">MONSIEUR EME</option>
                                                <option value="NEO EDITIONS">NEO EDITIONS</option>
                                                <option value="RINEVA">RINEVA</option>
                                                <option value="VIRGINIE TASSERY">VIRGINIE TASSERY</option>
                                                <option value="SOCIETE VD LTD">SOCIETE VD LTD</option>
                                                <option value="PRETRE">PRETRE</option>
                                            </select>
                                            
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div>
                                            <select
                                                className="col-sm-12"
                                                onChange={handleDropdownChangeRole}
                                                style={{ height: "40px", borderRadius: '6px', fontFamily: 'initial' }}
                                                required
                                            >
                                                <option value="">Choisir le rôle</option>
                                                <option value="ADMIN">Admin</option>
                                                <option value="USER">Utilisateur</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="">
                                        <a className="btn btn-secondary btn-envoie" onClick={handleValidation}>
                                            AJOUTER
                                        </a>
                                    </div>
                                </center>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

            <button className="btn svg" data-bs-target={'#editerUser-' + nom} data-bs-toggle="modal" >
                <img className="btn btn-outline-light" src="/images/icone_ajout_user.svg" alt="ajouter un utilisateur" />
            </button>
        </>
    );
};

export { AddUser };





type UpdateuserProps = {
    id : number | null;
    nom: string;
    login: string;
    role: string;
    password: string;
    idClient: string;
};


    
//permet d'editer les utilisateurs en mêmem temps

const Updateuser: React.FC<UpdateuserProps>= (props) => {
    const [id, setId] = useState<number | null>(props.id);
    const [nom, setNom] = useState(props.nom);
    const [login, setLogin] = useState(props.login);
    const [role, setRole] = useState(props.role);
    const [mdp, setmdp] = useState(props.password);
    const [nomClient, setnomClient] = useState(props.idClient);
    const [isLoading, setIsLoading] = useState(false);

    const token = getAuthToken();


    useEffect(() => {
        setId(props.id)
        setNom(props.nom);
        setLogin(props.login)
        setRole(props.role)
        setnomClient(props.idClient)
      },[props])
    // Charger les options depuis votre API en utilisant Axios



    const handleDropdownChangeOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setnomClient(selectedValue);
    };
    const handleDropdownChangeRole = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        if(selectedValue=="ADMIN"){
            setnomClient("ADMIN_VANESSA");
            setRole(selectedValue);
        }
        setRole(selectedValue);
    };

    const handleValidation = async () => {
        setIsLoading(true);
        try {
            // Effectue la recherche en utilisant l'endpoint approprié avec Axios
            const token = getAuthToken(); // Récupérer le token du local storage
            const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
            const data = {
                "id": id,
                "nom": nom,
                "login": login,
                "role": role,
                "password": mdp,
                "nomClient": nomClient
            }


            // Préparer les headers avec le token (s'il existe)
            try {
                axios.post('https://afone-global-config.dt-srv-194.ucorp.io/updateUser',data, {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },

            }).then(response => {
                // Faire quelque chose avec la réponse du deuxième endpoint en cas de succès
                let btn = document.getElementById('btn-close-edit')

                btn?.click()
                setIsLoading(false); // Mettez à jour l'état pour indiquer que l'upload est terminé
                window.location.reload();

            })               

            } catch (error) {
                console.error("Erreur d'ajout d'utilisateur :", error);

            }

            // Appelle la fonction onValidation avec la réponse de la recherche pour passer à l'étape suivante (étape 2)
        } catch (error) {
            // Gère les erreurs de la recherche ici
        }

    };
    const handleChangeNom = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNom(event.target.value);

    };
    const handleChangeLogin = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLogin(event.target.value)
    };
    const handleChangeMdp = (event: React.ChangeEvent<HTMLInputElement>) => {
        setmdp(event.target.value)
    };

    return (
        <>

            <div className="modal fade" id={"updateuser-" + nom} aria-hidden="true" aria-labelledby="exampleModalToggleLabel2">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <form action="">
                                <div className="float-end">
                                    <button type="button" id="btn-close-edit" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div><br />
                                <center>
                                    <h2><b>Editer l'utilisateur</b></h2>
                                    <h3><b>{nom}</b></h3>
                                    <div className="row mb-3">
                                        <div className="col-sm-12">
                                            <input type="text"
                                                className="form-control"
                                                id="UpdateNom" placeholder='nom'
                                                value={nom}
                                                onChange={handleChangeNom} 
                                                required/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-sm-12">
                                            <input type="text"
                                                className="form-control"
                                                id="UpdateLogin" placeholder='login'
                                                value={login}
                                                onChange={handleChangeLogin} 
                                                required/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-sm-12">
                                            <input type="password"
                                                className="form-control"
                                                id="UpdateMdp"
                                                placeholder="Password"
                                                onChange={handleChangeMdp} 
                                                required/>
                                        </div>
                                    </div>
                                    
                                    <div className="row mb-3">
                                        <div>
                                        <select
                                                className="col-sm-12"
                                                onChange={handleDropdownChangeOption}
                                                style={{ height: "40px", borderRadius: '6px', fontFamily: 'initial' }}
                                                required
                                            >
                                                <option value="">Sélectionner un client</option>
                                                <option value="EUROMULTIMEDIA">EUROMULTIMEDIA</option>
                                                <option value="FOUCAT">FOUCAT</option>
                                                <option value="HV SARL">HV SARL</option>
                                                <option value="LE TELEPHONE ROSE DE JULIE">LE TELEPHONE ROSE DE JULIE</option>
                                                <option value="MONSIEUR EME">MONSIEUR EME</option>
                                                <option value="NEO EDITIONS">NEO EDITIONS</option>
                                                <option value="RINEVA">RINEVA</option>
                                                <option value="VIRGINIE TASSERY">VIRGINIE TASSERY</option>
                                                <option value="SOCIETE VD LTD">SOCIETE VD LTD</option>
                                                <option value="PRETRE">PRETRE</option>

                                            </select>
                                            
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div>
                                            <select
                                                className="col-sm-12"
                                                onChange={handleDropdownChangeRole}
                                                style={{ height: "40px", borderRadius: '6px', fontFamily: 'initial' }}
                                                required
                                            >
                                                <option value="">Choisir le rôle</option>
                                                <option value="ADMIN">Admin</option>
                                                <option value="USER">Utilisateur</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="">
                                        <a className="btn btn-secondary btn-envoie" onClick={handleValidation}>
                                            EDITER
                                        </a>
                                        {isLoading ? (
                                            <div className="spinner">
                                                <BarLoader color={"white"} loading={isLoading} width={'100%'} />
                                            </div>
                                        ) : (
                                            <ToastContainer />
                                        )}
                                    </div>
                                </center>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

            
        </>
    );
};

export { Updateuser };








//Supprimer un utilisateur
type DeletuseruserProps = {
    id : number | null;
    nom: string;

};


    
//permet d'editer les utilisateurs en mêmem temps

const DeleteUser: React.FC<DeletuseruserProps>= (props) => {
    const [id, setId] = useState<number | null>(props.id);
    const [nom, setNom] = useState(props.nom);
    const [isLoading, setIsLoading] = useState(false);

    // const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const token = getAuthToken();


    useEffect(() => {
        setId(props.id)
        setNom(props.nom)
    
      },[props])
    // Charger les options depuis votre API en utilisant Axios




    const handleValidation = async () => {
        try {
            // Effectue la recherche en utilisant l'endpoint approprié avec Axios
            const token = getAuthToken(); // Récupérer le token du local storage
            const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
            const data = {
                "id": id
            }


            // Préparer les headers avec le token (s'il existe)
            try {
                axios.post('https://afone-global-config.dt-srv-194.ucorp.io/deleteUser',data, {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                
            }).then(response => {
                // Faire quelque chose avec la réponse du deuxième endpoint en cas de succès
                let btn = document.getElementById('btn-close-delete')

                btn?.click()
                setIsLoading(false); // Mettez à jour l'état pour indiquer que l'upload est terminé
                window.location.reload();

            })                

            } catch (error) {
                console.error("Erreur d'ajout d'utilisateur :", error);

            }

            // Appelle la fonction onValidation avec la réponse de la recherche pour passer à l'étape suivante (étape 2)
        } catch (error) {
            // Gère les erreurs de la recherche ici
        }

    };

    return (
        <>

            <div className="modal fade" id={"supprimer"} aria-hidden="true" aria-labelledby="exampleModalToggleLabel2">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <form action="">
                                <div className="float-end">
                                    <button type="button" id="btn-close-delete" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div><br />
                                <center>
                                    <h2><b>Supprimer l'utilisateur</b></h2>
                                    <h3><b>{nom}</b></h3>
                                    <div className="">
                                        <a className="btn btn-danger btn-envoie" onClick={handleValidation}>
                                            SUPPRIMER
                                        </a>
                                        {isLoading ? (
                                            <div className="spinner">
                                                <BarLoader color={"white"} loading={isLoading} width={'100%'} />
                                            </div>
                                        ) : (
                                            <ToastContainer />
                                        )}
                                    </div>
                                </center>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

            
        </>
    );
};

export { DeleteUser };



