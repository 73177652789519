import { useEffect, useState } from "react";
import axios from "axios";
import { getAuthToken } from '../helpers/axios_helper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BarLoader } from "react-spinners";


type DeletesonPubProps = {
    numero: string | null;
    base: string;
    path: string | null;
    pub: string;
    remove:any;
    id:string;
};



const DeleteSonPub: React.FC<DeletesonPubProps> = (props) => {
    const [pub, setPub] = useState(props.pub);
    const [uploaded, setUploaded] = useState(false);
    const [base, setbase] = useState(props.base);
    const [numero, setNumero] = useState(props.numero);
    const [path, setPath] = useState(props.path);
    const [id, setId] = useState(props.id);
    const [isLoading, setIsLoading] = useState(false);
    // const [nouveaunom, setNouveauNom] = useState("");

    useEffect(() => {
        setbase(props.base)
        setNumero(props.numero)
        setPath(props.path)
        setPub(props.pub)

    }, [props])


    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const showSuccessToast = () => {
        toast.success("Suppression du fichier réussie !");
    };

    const handleDelete = () => {
        const token = getAuthToken();

        let app_type = base.toLowerCase().toString();



        if (app_type === 'ecp') {
            const token = getAuthToken();

            const data = {
                numero: numero
            };
            axios.post("https://afone-global-config.dt-srv-194.ucorp.io/traitementFichier/json", data, {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                timeout: 5000,
            })
                .then(response => {
                    const nomfichier = pub;
                    const data = {
                        numero: numero,
                        pub: nomfichier.replace(".wav", "")
                    };
                    axios.post("https://afone-global-config.dt-srv-194.ucorp.io/config/deletePubEcp", data, {

                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        timeout: 5000,
                    })

                }).then(rep => {
                    // console.log(id)
                    props.remove(id)
                    let btn = document.getElementById('btn-close-delete')

                     btn?.click()
                    setIsLoading(false); // Mettez à jour l'état pour indiquer que l'upload est terminé
                    showSuccessToast();
                    // window.location.reload();
                }

                )
                .catch(error => {
                    console.log(error)
                    // Gérer les erreurs du deuxième endpoint
                    //   window.alert('Erreur lors de la requête au deuxième endpoint :' + error2.message);
                });

        }

        else if (app_type === 'tcv') {
            const data = {
                numero: numero,
            };
            axios.post("https://afone-global-config.dt-srv-194.ucorp.io/config/deletePubTcv", data, {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                timeout: 5000,


            })

                .then(response3 => {
                    // console.log(id)
                    props.remove(id)
                    let btn = document.getElementById('btn-close-delete')

                     btn?.click()
                    setIsLoading(false); // Mettez à jour l'état pour indiquer que l'upload est terminé
                    showSuccessToast();
                })
                .catch(error2 => {
                    console.log(error2)
                    // Gérer les erreurs du deuxième endpoint
                    //   window.alert('Erreur lors de la requête au deuxième endpoint :' + error2.message);
                });
        }
        else if (app_type === '2rmusic') {
            axios.put("https://afone-global-config.dt-srv-194.ucorp.io/config/deletePub2rMusic", null, {

                params: {
                    numero: numero,
                },
                headers: {

                    'Authorization': `Bearer ${token}`,
                },
                timeout: 5000,


            }).then(response3 => {
                    // console.log(id)
                    props.remove(id)
                    let btn = document.getElementById('btn-close-delete')

                     btn?.click()
                    setIsLoading(false); // Mettez à jour l'état pour indiquer que l'upload est terminé
                    showSuccessToast();
            })
        }


    };


    return (
        <>
            <div className="modal fade" id="deleteSonPub" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="float-end">
                                <button type="button" className="btn-close" id="btn-close-delete" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div><br />
                            <center>
                                <h4><b>CONFIRMER LA SUPPRESSION?</b></h4>
                                <br />
                                <div className="row">
                                    <div className="col">

                                        <button type="button" className="btn btn-info btn-envoie" onClick={handleDelete}>
                                            CONFIRMER
                                        </button>
                                    </div>
                                    <div className="col">
                                        <button type="button" className="btn btn-secondary btn-envoie" data-bs-dismiss="modal" aria-label="Close">
                                            ANNULER
                                        </button>
                                    </div>
                                </div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export { DeleteSonPub }



type EditesonPubProps = {
    numero: string | null;
    base: string;
    path: string | null;
};



const AddsonPub: React.FC<EditesonPubProps> = (props) => {
    const [typeson, setTypeson] = useState();
    const [uploaded, setUploaded] = useState(false);
    const [base, setbase] = useState(props.base);
    const [numero, setNumero] = useState(props.numero);
    const [path, setPath] = useState(props.path);
    const [isLoading, setIsLoading] = useState(false);
    // const [nouveaunom, setNouveauNom] = useState("");

    useEffect(() => {
        setbase(props.base)
        setNumero(props.numero)
        setPath(props.path)


    }, [props])


    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const showSuccessToast = () => {
        toast.success("Chargement du fichier réussie !");
    };

    const handleUpload = () => {
        if (selectedFile) {
            // Créez une instance de FormData pour envoyer le fichier au premier endpoint
            const formData = new FormData();
            formData.append('file', selectedFile);
            const token = getAuthToken();

            let app_type = base.toLowerCase().toString();


            if (app_type === 'ecp') {
                setIsLoading(true);
                // Remplacez 'URL_DU_ENDPOINT' par l'URL réelle de votre premier endpoint POST
                axios.post('https://afone-global-config.dt-srv-194.ucorp.io/traitementFichier/uploadFile', formData, {
                    // Inclure le token d'authentification dans les en-têtes de la requête
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    },
                })

                    .then(response => {
                        // Récupérer le nom du fichier retourné par le premier endpoint
                        const uploadedFileName = response.data.fileName;
                        const fullPath = "./uploads/" + uploadedFileName;


                        // Remplacez 'URL_DU_DEUXIEME_ENDPOINT' par l'URL réelle de votre deuxième endpoint POST
                        axios.get('https://afone-global-config.dt-srv-194.ucorp.io/traitementFichier/checkPub', {

                            params: {
                                numero: numero,
                                file: fullPath,
                                regroupement: 'PUB',


                            },


                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }
                        })
                            .then(response3 => {
                                // Faire quelque chose avec la réponse du deuxième endpoint en cas de succès

                                const newname = uploadedFileName.replace(".wav", "");

                                // Faire quelque chose avec la réponse du deuxième endpoint en cas de succès
                                const data = {
                                    numero: numero,
                                    regroupement: 'PUB',
                                    nomFichier: 'Pub_' + newname,
                                };
                                axios.post("https://afone-global-config.dt-srv-194.ucorp.io/traitementFichier/json", data, {



                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${token}`,
                                    },


                                })

                                    .then(response4 => {


                                        const name = uploadedFileName.replace(".wav", "");

                                        // Faire quelque chose avec la réponse du deuxième endpoint en cas de succès
                                        const data = {
                                            numero: numero,
                                            newPub: 'Pub_' + name
                                        };
                                        axios.post("https://afone-global-config.dt-srv-194.ucorp.io/config/createPubEcp", data, {



                                            headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': `Bearer ${token}`,
                                            },


                                        }).then(err => {
                                            setIsLoading(false); // Mettez à jour l'état pour indiquer que l'upload est terminé
                                            showSuccessToast();
                                            window.location.reload();
                                        })
                                    })
                                    .catch(error2 => {
                                        console.log(error2)
                                        // Gérer les erreurs du deuxième endpoint
                                        //   window.alert('Erreur lors de la requête au deuxième endpoint :' + error2.message);
                                    });


                            })
                            .catch(error3 => {
                                console.log(error3)
                                // Gérer les erreurs du deuxième endpoint
                                //   window.alert('Erreur lors de la requête au deuxième endpoint :' + error2.message);
                            });
                    }
                    )
                    .catch(error => {
                        console.log(error)
                        // Gérer les erreurs du premier endpoint
                        window.alert('Erreur lors de l\'upload du fichier :' + error.message);
                    });

            }

            else if (app_type === 'tcv') {
                setIsLoading(true)
                axios.post('https://afone-global-config.dt-srv-194.ucorp.io/traitementFichier/uploadFile', formData, {
                    // Inclure le token d'authentification dans les en-têtes de la requête
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    },
                })
                    .then(response => {

                        // Récupérer le nom du fichier retourné par le premier endpoint
                        const uploadedFileName = response.data.fileName;

                        const fullPath = "./uploads/" + uploadedFileName;



                        // Remplacez 'URL_DU_DEUXIEME_ENDPOINT' par l'URL réelle de votre deuxième endpoint POST
                        axios.get('https://afone-global-config.dt-srv-194.ucorp.io/traitementFichier/checkPub', {

                            params: {
                                numero: numero,
                                file: fullPath,
                                regroupement: 'PUB'


                            },


                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }
                        })
                            .then(response2 => {

                                const newname = uploadedFileName.replace(".wav", "");

                                // Faire quelque chose avec la réponse du deuxième endpoint en cas de succès
                                console.log(response2.data.message);
                                const data = {
                                    numero: numero,
                                    newPub: 'Pub_' + newname,
                                };
                                axios.post("https://afone-global-config.dt-srv-194.ucorp.io/config/updatePubTcvMessagerie", data, {



                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${token}`,
                                    },


                                })

                                    .then(response3 => {
                                        setIsLoading(false); // Mettez à jour l'état pour indiquer que l'upload est terminé
                                        showSuccessToast();
                                        window.location.reload();
                                    })
                                    .catch(error2 => {
                                        console.log(error2)
                                        // Gérer les erreurs du deuxième endpoint
                                        //   window.alert('Erreur lors de la requête au deuxième endpoint :' + error2.message);
                                    });
                            })
                            .catch(error3 => {
                                console.log(error3)
                                // Gérer les erreurs du deuxième endpoint
                                //   window.alert('Erreur lors de la requête au deuxième endpoint :' + error2.message);
                            });

                    })
                    .catch(error => {
                        console.log(error)
                        // Gérer les erreurs du premier endpoint
                        window.alert('Erreur lors de l\'upload du fichier :' + error.message);
                    });

            }
            else if (app_type === '2rmusic') {
                setIsLoading(true);
                axios.post('https://afone-global-config.dt-srv-194.ucorp.io/traitementFichier/uploadFile', formData, {
                    // Inclure le token d'authentification dans les en-têtes de la requête
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    },
                })
                    .then(response => {

                        // Récupérer le nom du fichier retourné par le premier endpoint
                        const uploadedFileName = response.data.fileName;
                        const fullPath = "./uploads/" + uploadedFileName;


                        // Remplacez 'URL_DU_DEUXIEME_ENDPOINT' par l'URL réelle de votre deuxième endpoint POST
                        axios.get('https://afone-global-config.dt-srv-194.ucorp.io/traitementFichier/checkPub', {

                            params: {
                                numero: numero,
                                file: fullPath,
                                regroupement: 'PUB',


                            },


                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }
                        })
                            .then(response2 => {

                                const newname = uploadedFileName.replace(".wav", "");

                                // Faire quelque chose avec la réponse du deuxième endpoint en cas de succès
                                axios.put("https://afone-global-config.dt-srv-194.ucorp.io/config/updatePub2rMusic", null, {

                                    params: {
                                        numero: numero,
                                        newPub: 'Pub_' + newname,
                                    },

                                    headers: {

                                        'Authorization': `Bearer ${token}`,
                                    },


                                })

                                    .then(response3 => {
                                        setIsLoading(false); // Mettez à jour l'état pour indiquer que l'upload est terminé
                                        showSuccessToast();
                                        window.location.reload();
                                    })
                                    .catch(error2 => {
                                        console.log(error2)
                                        // Gérer les erreurs du deuxième endpoint
                                        //   window.alert('Erreur lors de la requête au deuxième endpoint :' + error2.message);
                                    });
                            })
                            .catch(error3 => {
                                console.log(error3)
                                // Gérer les erreurs du deuxième endpoint
                                //   window.alert('Erreur lors de la requête au deuxième endpoint :' + error2.message);
                            });

                    })
                    .catch(error => {
                        console.log(error)
                        // Gérer les erreurs du premier endpoint
                        window.alert('Erreur lors de l\'upload du fichier :' + error.message);
                    });

            }

        }
    };



    return (
        <>
            <div className="modal fade" id='editerSonPub' aria-hidden="true" aria-labelledby="exampleModalToggleLabel" >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <form action="">
                                <div className="float-end">
                                    <button type="button" id="btn-close-update" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div><br />
                                <center>
                                    <h2><b>Ajouter un son</b></h2>
                                    <h6 className="mb-5"><b>Sur pub</b></h6><br />

                                    <div className="input-group" style={{ width: "50%" }}>
                                        <label htmlFor="audioPub" className="btn btn-light">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                                            </svg>&nbsp; Selectionner un fichier
                                        </label>
                                        <br />
                                        <input type="file" id="audioPub" accept="audio/wav" style={{ display: "none" }} onChange={handleFileChange} />
                                    </div><br />
                                    <div className="">

                                        <a className="btn btn-secondary btn-envoie" onClick={handleUpload}>
                                            Envoyer
                                        </a>
                                        {isLoading ? (
                                            <div className="spinner">
                                                <BarLoader color={"white"} loading={isLoading} width={'100%'} />
                                            </div>
                                        ) : (
                                            <ToastContainer />
                                        )}
                                    </div>
                                </center>
                            </form>

                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export { AddsonPub }